import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticated } from '../auth/auth.guard';
import { EconomicSummaryComponent } from './economic-summary/economic-summary.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [authenticated],
    children: [
      {
        path: 'summary',
        component: EconomicSummaryComponent,
      },
      {
        path: 'startup',
        loadChildren: () =>
          import('../start-up-economics/start-up-economics.module')
            .then(m => m.StartUpEconomicsModule)
      },
      {
        path: 'income',
        loadChildren: () =>
          import('../income/income.module')
            .then(m => m.IncomeModule)
      },
      {
        path: 'expenses',
        loadChildren: () =>
          import('../expenses/expenses.module')
            .then(m => m.ExpensesModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EconomicsRoutingModule { }
