import { input, Component } from '@angular/core';

@Component({
  selector: 'economic-summary-table',
  templateUrl: './summary-table.component.html'
})
export class SummaryTableComponent {
  turn = input.required<number>();
  incomeTotal = input.required<number>();
  expensesTotal = input.required<number>();
  extractedHeading = input<string>();
  extractedTotal = input<number>();
};
