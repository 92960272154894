import { Component, Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Observable, map, switchMap, tap } from 'rxjs';

import { RaceService } from '../../services/race.service';
import { SessionService } from '../../services/session.service';
import { TransactionService } from '../../services/transaction.service';

import { Campaign } from '../../interfaces/campaign';
import { Race } from '../../interfaces/race';
import { KeyedMiniTransaction, Transaction } from '../../interfaces/transaction';
import { CampaignWithRace } from '../../base/campaign-race';

@Component({
  selector: 'economic-summary',
  templateUrl: './economic-summary.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class EconomicSummaryComponent extends CampaignWithRace {
  private transactionService = inject(TransactionService);
  router = inject(Router);
  incomeTotal: number = 0;
  expensesTotal: number = 0;
  remainder: number = 0;
  campaignTurn: number = 1;
  editTurn: WritableSignal<number> = signal(0);
  editTurn$: Observable<number>;
  isPlayerRace: boolean;
  canPerformAction: boolean = true;
  canSubmit: boolean = true;
  canChangeReadyFlag: boolean = true;

  transactions$: Observable<Transaction[] | undefined>;
  transactions: Signal<Transaction[] | undefined>;
  summarizedTransactions: KeyedMiniTransaction;

  constructor () {
    super();
    this.session.iAmBusy();
    if (this.campaign() == null || this.race() == null) {
      this.router.navigate(['/user/assignments']);
    }

    this.campaignTurn = this.campaign().turn;
    this.editTurn = this.session.turnEdit;
    this.isPlayerRace = this.race().playerRace === 1 || false;

    this.transactions = toSignal(this.transactionService.getOrBuildTransactions(this.race() as Race, this.session.turnEdit()));
    this.editTurn$ = toObservable(this.editTurn);

    this.transactions$ = this.editTurn$.pipe(
      switchMap(turn => this.transactionService.getOrBuildTransactions(this.race() as Race, turn))
    );
    this.transactions = toSignal(this.transactions$, { initialValue: [] });
    effect(() => {
      if (!this.transactions()) {
        return;
      }
      this.session.iAmBusy();
      this.summarizedTransactions = this.transactionService.buildSummaryFromTransactions(this.transactions());
      if (Object.keys(this.summarizedTransactions || {}).length === 0) {
        return;
      }
      this.processTransactionData(this.summarizedTransactions);
      this.session.iAmNotBusy();
    });

    this.session.iAmNotBusy();

    console.log('summary', this);
  };

  private setStandardEconomicFlags () {
    this.canSubmit = (this.editTurn() === this.campaignTurn) &&
      ((this.race().economicsCompleteTurn || 0) === this.campaignTurn - 1);
    this.canChangeReadyFlag = (this.race().readyForEconomicsTurn === this.editTurn()) &&
      (this.race().economicsCompleteTurn || 0) < this.campaignTurn;
    this.canPerformAction = this.canSubmit || this.canChangeReadyFlag;
  };

  private processTransactionData (transactions: { [key: string]: any; }) {
    this.incomeTotal =
      ((transactions['popIncomeTotal'] || {}).amount || 0) +
      ((transactions['tradeIncomeTotal'] || {}).amount || 0) +
      ((transactions['leaseIncomeTotal'] || {}).amount || 0) +
      ((transactions['shipSaleTotal'] || {}).amount || 0) +
      ((transactions['otherIncomeTotal'] || {}).amount || 0);
    this.expensesTotal =
      ((transactions['maintenanceTotal'] || {}).amount || 0) +
      ((transactions['constructionTotal'] || {}).amount || 0) +
      ((transactions['industrialTotal'] || {}).amount || 0) +
      ((transactions['colonizationTotal'] || {}).amount || 0) +
      ((transactions['troopsTotal'] || {}).amount || 0) +
      ((transactions['researchTotal'] || {}).amount || 0) +
      ((transactions['techItemTotal'] || {}).amount || 0) +
      ((transactions['otherExpenseTotal'] || {}).amount || 0);
    this.remainder = this.incomeTotal - this.expensesTotal;
    this.setStandardEconomicFlags();
    return transactions;
  };

  private updateRace (raceChange: any) {
    this.raceService.saveRace(raceChange);
    this.session.iAmNotBusy();
  };

  updateReady (readyTurn: number) {
    this.session.iAmBusy();
    this.race().readyForEconomicsTurn = readyTurn;
    this.setStandardEconomicFlags();
    return this.updateRace(this.race());
  };

  economicsReady () {
    this.updateReady(this.editTurn());
  };

  economicsNotReady () {
    this.updateReady((this.race().readyForEconomicsTurn || this.editTurn()) - 1);
  };

  updateEconomicTurn (newTurn: number) {
    this.session.iAmBusy();
    this.editTurn.set(newTurn);
    this.session.turnEdit.set(newTurn);
    this.session.iAmNotBusy();
  };
};
