<edit-dialog (action)="signalAction($event)" [title]="title" class="flex flex-col">
  <ng-container edit-body>
    <div class="modal-center flex flex-col">
      <gray-label-wrapper [fieldLabel]="'Free PCF'" [fieldName]="'free_pcf'" class="w-full text-black flex items-center">
        <span class="m-1 p-1 ism-black">{{ freePcf }}</span>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'PCF Quantity'" [fieldName]="'pcf_quantity'" class="w-full text-black flex items-center">
        <input id="pcf_quantity" class="m-1 p-1 w-1/4 form-input ism-black" type="number" min="{{ 0 - freePcf }}" max="{{ maxPcf - pcfaTransaction.quantity }}" [(ngModel)]="pcfTransaction.quantity" (change)="calculateTotal()" required />
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Cost Each'" [fieldName]="'cost_per'" class="w-full text-black flex items-center">
        <span class="m-1 p-1 ism-black">{{ pcfCost }}</span>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Total'" [fieldName]="'pcf_total'" class="w-full text-black flex items-center">
        <span class="m-1 p-1 font-semibold ism-black">{{ pcfTransaction.amount }}</span>
      </gray-label-wrapper>
      <hr class="ism-black w-full" />
      <gray-label-wrapper [fieldLabel]="'Free PCF-a'" [fieldName]="'free_pcfa'" class="w-full text-black flex items-center">
        <span class="m-1 p-1 ism-black">{{ freePcfa }}</span>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'PCFa Quantity'" [fieldName]="'pcfa_quantity'" class="w-full text-black flex items-center">
        <input id="pcfa_quantity" class="m-1 p-1 w-1/4 form-input ism-black" type="number" min="{{ 0 - freePcfa }}" max="{{ maxPcf - pcfTransaction.quantity }}" [(ngModel)]="pcfaTransaction.quantity" (change)="calculateTotal()" required />
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Cost Each'" [fieldName]="'pcfa_cost'" class="w-full text-black flex items-center">
        <span class="m-1 p-1 ism-black">{{ pcfaCost }}</span>
      </gray-label-wrapper>
      <gray-label-wrapper [fieldLabel]="'Total'" [fieldName]="'pcfa_total'" class="w-full text-black flex items-center">
        <span class="m-1 p-1 font-semibold ism-black">{{ pcfaTransaction.amount }}</span>
      </gray-label-wrapper>
      <hr class="ism-black w-full" />
      <gray-label-wrapper [fieldLabel]="'Grand Total'" [fieldName]="'grand_total'" class="w-full text-black flex items-center">
        <span class="m-1 p-1 font-bold text-black">{{ pcfTransaction.amount + pcfaTransaction.amount }}</span>
      </gray-label-wrapper>
    </div>
  </ng-container>
</edit-dialog>
