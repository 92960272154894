<table id="_FINANCES_" class="mr-2 ism-white">
  <tr class="data">
    <td class="text-center text-1.5 ism-gold" colspan="2">Finances</td>
  </tr>
  <tr class="data">
    <td class="text-right">Current Turn:</td>
    <td class="text-center">{{ turn() }}</td>
  </tr>
  <tr class="data">
    <td class="text-right">Total Income:</td>
    <td class="text-right">{{ incomeTotal() | number: "1.2-2" }} MC</td>
  </tr>
  <tr class="data">
    <td class="text-right">Other Expenses:</td>
    <td class="text-right">{{ expensesTotal() | number: "1.2-2" }} MC</td>
  </tr>
  <!-- @if (extractedHeading() && extractedTotal() != null) { -->
  <tr class="data">
    <td class="text-right">{{ extractedHeading() || "Page Total:" }}</td>
    <td class="text-right">{{ extractedTotal() | number: "1.2-2" }} MC</td>
  </tr>
  <!-- } -->
  <tr class="data">
    <td class="text-right">Available:</td>
    <td class="text-right">{{ incomeTotal() - expensesTotal() - extractedTotal() | number: "1.2-2" }} MC</td>
  </tr>
</table>
