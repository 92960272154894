import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from "@angular/forms";

import { EconomicsRoutingModule } from './economics-routing.module';
import { SharedModule } from '../shared/shared.module';

import { EconomicSummaryComponent } from './economic-summary/economic-summary.component';
import { TransactionBaseComponent } from './transaction-base/transaction-base.component';
import { TroopTransactionEditComponent } from './troop-transaction-edit/troop-transaction-edit.component';
import { SummaryTableComponent } from './summary-table/summary-table.component';

@NgModule({
  declarations: [
    EconomicSummaryComponent,
    TransactionBaseComponent,
    TroopTransactionEditComponent,
    SummaryTableComponent,
  ],
  imports: [
    CommonModule,
    EconomicsRoutingModule,
    FormsModule,
    SharedModule
  ],
  exports: [
    TransactionBaseComponent,
    TroopTransactionEditComponent,
    SummaryTableComponent,
  ]
})
export class EconomicsModule { }
