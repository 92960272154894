import { Component } from '@angular/core';

import { DialogHandlerComponent } from '../../shared/dialog-handler/dialog-handler.component';

import { Transaction } from '../../interfaces/transaction';

@Component({
  selector: 'troop-transaction-edit',
  templateUrl: './troop-transaction-edit.component.html'
})
export class TroopTransactionEditComponent extends DialogHandlerComponent {
  title: string = 'Planetary Control Forces Transaction Editor';
  pcfTransaction: Transaction;
  pcfaTransaction: Transaction;
  pcfCost: number;
  pcfaCost: number;
  maxPcf: number;
  freePcf: number;
  freePcfa: number;

  constructor () {
    super();
    this.pcfTransaction = this.data.document as Transaction;
    this.pcfaTransaction = this.data['pcfaTransaction'] as Transaction;
    this.pcfCost = this.data['pcfCost'] as number;
    this.pcfaCost = this.pcfCost * 1.25;
    this.maxPcf = this.data['maxPcf'] as number;
    this.freePcf = this.data['freePcf'] as number;
    this.freePcfa = this.data['freePcfa'] as number;
  };

  calculateTotal () {
    this.pcfTransaction.amount = 0;
    if (this.pcfTransaction.quantity >= 0) {
      this.pcfTransaction.amount = this.pcfTransaction.quantity * this.pcfCost;
    }
    this.pcfaTransaction.amount = 0;
    if (this.pcfaTransaction.quantity >= 0) {
      this.pcfaTransaction.amount = this.pcfaTransaction.quantity * this.pcfaCost;
    }
  };

  override save () {
    this.dialogRef.close({
      documentName: "Transaction",
      document: this.pcfTransaction,
      pcfaTransaction: this.pcfaTransaction
    });
  };
};
